/* Warning: please do not statically import any dependencies here except for static assets like icons */
// import icon from './file-code-solid.svg'
// import { ReactComponent as icon } from './file-code-solid.svg'
import { EyeIcon } from '@panwds/icons'
import { FawkesCloudServicesResponse, ZRestResponse, FetchStatus } from './data/queryResultsDefinition';
import { ZGet, Products } from './shared/backend';
import AppMgmtUDAUrls from './shared/urls';
import Logger from './shared/Logger';

const templateNav = {
    navigation: [{
        key: 'example',
        title: 'Example',
        expandable: true,
        path: '/example',
        noRBACheck: true,
        exact: false,
        icon: EyeIcon, // either svg data url or panwds icon component, only needed in the first level
        mergeNav: true, // required if the first level is shared
        children: [{
            key: 'example-page',
            title: 'Example Page',
            path: '/example/page',
            noRBACheck: true,
            // no component, specified in upper level
        }],
        // in this example, the micro-app owns the first level, if the first level is shared, please have below in the 2nd level or whatever level it owns
        contentClassName: 'example-root panwds-tw3', // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: () => import('./AppMgmtRoutes'), // any component must be dynamically import like this
    }]
};

const appMgtNavWithSubNav: Object = {
    navigation: [{
    key: 'appMgmtWorkflows',
    title: 'Workflows',
    expandable: true,
    path: '/workflows',
    noRBACheck: true,
    exact: false,
    mergeNav: true,
    children: [{
        key: 'app-management',
        title: 'Agentless Apps',
        path: '/workflows/app-management',
        noRBACheck: true,
        contentClassName: 'panwds-tw3',
        component: () => import('./AppMgmtRoutes'),
        children: [
            {
                key: 'app-management-template',
                title: 'Agentless Apps from Sparky Template',
                path: '/workflows/app-management/template',
                noRBACheck: true,
                contentClassName: 'panwds-tw3',
            },
            {
                key: 'app-management-config',
                title: 'Agentless Apps',
                path: '/workflows/app-management',
                noRBACheck: true,
                contentClassName: 'panwds-tw3',
            }
        ]
    }],
}]
}

const appMgtNav: Object = {
    navigation: [{
    key: 'appMgmtWorkflows',
    title: 'Workflows',
    expandable: true,
    path: '/workflows',
    noRBACheck: true,
    exact: false,
    mergeNav: true,
    children: [{
        key: 'app-management',
        title: 'Agentless Apps',
        path: '/workflows/app-management',
        noRBACheck: true,
        contentClassName: 'panwds-tw3',
        component: () => import('./AppMgmtRoutes'), // any component must be dynamically import like this
        children:[{
            key: 'app-management-config',
            title: 'App Management',
            path: '/workflows/app-management',
            noRBACheck: true,
            contentClassName: 'panwds-tw3',
        }]
    }],
}]
}

export default function init() {
    return async function tsgLoad() {
        let response: ZRestResponse | undefined = undefined;
        let template: Object | undefined = undefined;

        try {
            response = await ZGet(AppMgmtUDAUrls.serverFawkesCloudServices, '', Products.FAWKES_JWT);
            const services: FawkesCloudServicesResponse[] = response as FawkesCloudServicesResponse[];

            template = (services && services[0]?.mobile_users_agentless_access) ? appMgtNav : undefined;
            // template  = appMgtNavWithSubNav;
        }
        catch(e) {
            const err = e as FetchStatus;
            Logger.error(`AppManagement: Error getting Fawkes cloud services - ${err?.status?.http_status}`);
            template = undefined;
        }
        
        // template = appMgtNav;

        return  template;
    };
};
