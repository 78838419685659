export interface RestAPI {
    [param: string]: string | number | boolean;
}

export const RequestMethods: AppAcclMap<string> = {
    get: 'GET',
    post: 'POST',
    delete: 'DELETE',
    put: 'PUT',
    patch: 'PATCH',
}

export interface AddOnInfo {
    license_type: string,
    license_expiration: string,
    in_grace_period: boolean
}

export interface FetchStatus {
    status: {
        error_code?: number,
        error_message?: string,
        http_status?: number,
        http_status_message?: string,
        ok?: boolean,
        stacktrace?: string
    }
}

export interface RestModifiers {
    mimeType?: string;
    noAuthKey?: boolean;
    accept?: string;
}

export interface AppAccelSettingsCommon {
    enabled: boolean,
    ports: string[]
}

export interface AppAccelSettingReq {
    app_acceleration: AppAccelSettingsCommon
}

export interface AppAccelSettingsResp extends FetchStatus {
    item: {
        app_acceleration: AppAccelSettingsCommon
    }
}

export interface AppAccelCapabilities extends FetchStatus {
    item: {
        app_acceleration: {
            capable: boolean
        }
    }
}

export interface FawkesCloudServicesResponse  {
    "mobile_users_explicit_proxy": boolean,
    "mobile_users_global_protect": boolean,
    "mobile_users_jupiter": boolean,
    "mobile_users_agentless_access": boolean
}

export interface DestinationDomain {
    "domain_name": string,      // called destination in the figma (FQDN/ip addr)
    "port": number,
    "protocol": string,
    "sni": string
}

export interface AgentlessAppDefinition {
    "app_name": string,
    "domain_description": string,
    "icon": string,
    "cert_name": string,
    "domain_name": string,          // called URL in the figma
    "domain_id"?: string,
    "origins": DestinationDomain[]
    "cname"?: string,
    "port"?: string,
    "is_ready"?: boolean
}

export interface AgentlessTableRow extends AgentlessAppDefinition {
    beingDelete?: boolean;
}

export interface AppManagementRequest {
    application: AgentlessAppDefinition
}

export interface AppManagementAppsResponse extends FetchStatus {
    items: AgentlessAppDefinition[]
}

export interface AppManagementAppResponse extends FetchStatus {
    item: AgentlessAppDefinition
}

export interface FawkesCertInfo {
    "@loc": string;                                // "Mobile Users Container",
    "@name": string;                               // "jira_demotenant_com",
    "@uuid": string;                               // "d482ffca-c94c-4d99-959c-19279725e6f9",
    "@type": string;                               // "container",
    "subject-hash": string;                        // "53b43cad",
    "issuer-hash": string;                         // "e3bc0f8e",
    "not-valid-before": string;                    // "Dec 20 18:09:45 2023 GMT",
    "issuer": string;                              // "/CN=Agentless root ca",
    "not-valid-after": string;                     // "Dec 19 18:09:45 2024 GMT",
    "common-name": string;                         // "jira.demotenant.com",
    "expiry-epoch": string;                        // "1734631785",
    "ca": string;                                  // "no",
    "subject": string;                             // example: "/CN=jira.demotenant.com",
    "public-key": string;
    "algorithm": string;                           // "RSA",
    "private-key": string;
    "common-name-int": string;                     // "jira.demotenant.com",
    "subject-int": string;                         // "CN = jira.demotenant.com"
}

export interface FawkesCertInfoResponse {
    "additional-info": string[],
    certificates: FawkesCertInfo[],
    ok: boolean,
    sslDecrypt: {},
    sslDecryptAdditionalInfo: []
}

export type ZRestRequest = 
    | AppManagementRequest

export type ZRestResponse = 
    | AgentlessAppDefinition
    | AppManagementAppResponse
    | FawkesCloudServicesResponse
    | FawkesCloudServicesResponse[]
    | FetchStatus
    | FawkesCertInfo
    | FawkesCertInfoResponse

export type AppAcclMap<type> = {
    [name: string]: type
}