export const makeQueryParams = (url: string, params: Record<string, unknown> | string): string => {
    let paramStr = '';
    
    if (typeof params === 'object') {
        const paramKeys = Object.keys(params);
        for (let i = 0, len = paramKeys.length; i < len; i++) {
            if (paramStr.length > 0) {
            paramStr += '&';
            }
            const key = paramKeys[i];
            paramStr += `${key}=${params[key]}`;
        }
    } else if (typeof params === 'string') {
        paramStr = params;
    }

    return `${url}?${paramStr}`;
}

export const AppMgmtUDAUrls = {
    udaHome: '/workflows/app-management',

    udaUrlModifier: '/sse/agentless_uda/v1.0/',
    udaUrlModifierStg1: '/sse/agentless_uda/stg1/v1.0/',
    udaUrlModifierStg2: '/sse/agentless_uda/stg2/v1.0/',

    // get from our apiGee
    serverUDADomains: 'domains',
    serverUDADomain: 'domain',

    // has fawkes enabled us?
    // serverFawkesCloudServices: '/api/sase/config/v1/cloud-services',
    serverFawkesCloudServices: 'sase/config/v1/cloud-services',
    serverFawkesCertInfo: '/api/config/v9.2/Device/CertificateManagement/CertificatesInfo?type=container&folder=Mobile%20Users%20Container&isBpa=no',

    // local client endpoints
    sparkyAppManagement: '/workflows/app-management',
    sparkyCreateEditService: '/workflows/app-management/createEditApp',
    sparkyCertManagement: '/manage/objects/certificate-management?container=mobile-users',
}

export default AppMgmtUDAUrls;
