import { IntlContext, MessageDescriptor } from "react-intl";
import { ColumnOptions } from '@panwds/react-table';

export const en_strs: Record<string, MessageDescriptor> = {
  appmgmtTitle: {
    id: 'appmgmtTitle',
    defaultMessage: "Agentless Apps",
  },
  appmgmtApplications: {
    id: 'appmgmtApplications',
    defaultMessage: "Applications",
  },
  appmgmtNavLabel: {
    id: 'appmgmtNavLabel',
    defaultMessage: "Agentless Apps",
  },
  appmgmtEnablementInProgress: {
    id: 'appmgmtInProgress',
    defaultMessage: 'Agentless Apps will be enabled momentarily. Please stand by.'
  },
  appmgmtEnabledFailure: {
    id: 'appmgmtEnablementFailed',
    defaultMessage: 'Agentless Apps could not be enabled. Please contact Global Customer Support'
  },

  // Main page - table related strings
  appmgmtSearch: {
    id: 'appmgmtSearch',
    defaultMessage: 'Search' 
  },
  appmgmtNameCol: {
    id: 'appmgmtNameCol',
    defaultMessage: 'Name' 
  },
  appmgmtPublicDomainNameCol: {
    id: 'appmgmtDomainNameCol',
    defaultMessage: 'Public Domain Name' 
  },
  appmgmtCNameCol: {
    id: 'appmgmtCNameCol',
    defaultMessage: 'CNAME' 
  },
  appmgmtDescriptionCol: {
    id: 'appmgmtDescriptionCol',
    defaultMessage: 'Description' 
  },
  appmgmtActionCol: {
    id: 'appmgmtActionCol',
    defaultMessage: 'Actions' 
  },
  appmgmtEnabledServicesCol: {
    id: 'appmgmtEnabledServices',
    defaultMessage: 'Enabled Services'
  },
  appmgmtCertificateCol: {
    id: 'appmgmtCertificate',
    defaultMessage: 'Certificate Id'
  },
  appmgmtDestinationCol: {
    id: 'appmgmtDestination',
    defaultMessage: 'Destination'
  },


  appmgmtAdd: {
    id: 'appmgmtAdd',
    defaultMessage: 'Add'
  },
  appmgmtDelete: {
    id: 'appmgmtDelete',
    defaultMessage: 'Delete'
  },
  appmgmtConfirm: {
    id: 'appmgmtConfirm',
    defaultMessage: 'Confirm'
  },
  appmgmtDisable: {
    id: 'appmgmtOk',
    defaultMessage: 'Disable'
  },
  appmgmtCancel: {
    id: 'appmgmtCancel',
    defaultMessage: 'Cancel'
  },
  appmgmtSave: {
    id: 'appmgmtSave',
    defaultMessage: 'Save'
  },
  appmgmtImport: {
    id: 'appmgmtImport',
    defaultMessage: 'Import'
  },

  // Add Web App Strings - These are strings for setting up an individual domain/application
  appmgmtAddWebApp: {
    id: 'appmgmtAddWebApp',
    defaultMessage: 'Add Web App'
  },

  // Strings for the general settings panel
  appmgmtGeneralSettings: {
    id: 'appmgmtGeneralSettings',
    defaultMessage: 'General Settings'
  },
    appmgmtAppName: {
    id: 'appmgmtAppName',
    defaultMessage: 'Name'
  },
  appmgmtAppNamePlaceholder: {
    id: 'appmgmtAppNamePlaceholder',
    defaultMessage: `Enter Application's Name`
  },
  appmgmtAppDescription: {
    id: 'appmgmtAppDescription',
    defaultMessage: 'Description'
  },  
  appmgmtAppDescriptionPlaceholder: {
    id: 'appmgmtAppDescriptionPlaceholder',
    defaultMessage: 'Enter Application Description'
  },  
  appmgmtUploadIcon: {
    id: 'appmgmtUploadIcon',
    defaultMessage: 'Upload App Icon'
  },

  // Strings for the service Settings panel
  appmgmtServiceSettings: {
    id: 'appmgmtServiceSettings',
    defaultMessage: 'Access Settings'
  },
  appmgmtUDADetails: {
    id: 'appmgmtUDADetails',
    defaultMessage: 'App Details'
  },
  appmgmtPublicDomain: {
    id: 'appmgmtPublicDomain',
    defaultMessage: 'Public Domain Name'
  },
  appmgmtPublicDomainExample: {
    id: 'appmgmtPublicDomainExample',
    defaultMessage: 'www.example.com'
  },
  appmgmtPublicDomainHelp: {
    id: 'appmgmtPublicDomainHelp',
    defaultMessage: `Enter the applications's public domain name`
  },
  appmgmtPublicDomainSetHelp: {
    id: 'appmgmtPublicDomainSetHelp',
    defaultMessage: `The public domain name cannot be changed once created`
  },
  
  appmgmtGeneratedCNAME: {
    id: 'appmgmtGeneratedCNAME',
    defaultMessage: 'CNAME'
  },
  appmgmtGeneratedCNAMEHelp: {
    id: 'appmgmtGeneratedCNAMEHelp',
    defaultMessage: 'CNAME cannot be changed once created'
  },
  appmgmtSSLCertificate: {
    id: 'appmgmtCertificate',
    defaultMessage: 'Certificate'
  },
  appmgmtSSLCertificatePlaceHolder: {
    id: 'appmgmtSSLCertificatePlaceHolder',
    defaultMessage: 'Choose a certificate'
  },
  appmgmtSSLCertificateHelp: {
    id: 'appmgmtSSLCertificateHelp',
    defaultMessage: 'Choose an existing certificate or import a new one'
  },
  appmgmtDestination: {
    id: 'appmgmtDestination',
    defaultMessage: 'Destination'
  },
  appmgmtOriginDestination: {
    id: 'appmgmtOriginDestination',
    defaultMessage: 'Enter you FQDN/IP Address'
  },
  
  appmgmtAddPublicRequired: {
    id: 'appmgmtRequired',
    defaultMessage:  ' Required Field'
  },

// ---
// Delete app confirmation popup strings
  appmgmtDeleteDomainTitle: {
    id: 'appmgmtDeleteDomainTitle',
    defaultMessage: 'Delete App from Agentless Access'
  },
  appMgmtDeleteModalMessage: {
    id: 'appMgmtDeleteModalMessage',
    defaultMessage: 'Are you sure you want to delete this app?'
  },

  // Enter Application Name
  // Enter Description
  // Upload App Icon 
  // example.com
  // Enter you preferred domain
  // Enter your FQDN/IP ADDR


  // generic error messages
  ErrorDupDomainName: { // 402 - 1010
    id: 'aacErrCBM',
    defaultMessage: "The public domain you have chosen is already in use.  Please choose a different public domain name.",
  },
  
  serverErrorGettingCerts: {    // 500
    id: 'serverErrorGettingCerts',
    defaultMessage: "A system error occurred acquiring Certificates for this tenant.  Please try again later and if the error persists contact Global Customer Support.",
  },

  // generic error messages
  TenantDoesNotExist: { // 403
    id: 'appmgmtErrCBM',
    defaultMessage: "The tenant cannot be found. Please contact Global Customer Support.",
  },
  serverDataChanged: {  // 404
    id: 'appmgmtErrCBM',
    defaultMessage: "The tenant cannot be found.  Please contact Global Customer Support.",
  },
  serverError: {    // 500
    id: 'appmgmtErrCBM',
    defaultMessage: "A system error has occurred. Please try again later and if the error persists contact Global Customer Support.",
  },
};